import Grid from "@mui/material/Grid2";
import Typography from "@mui/material/Typography";
import {useLoaderData, useNavigate} from "react-router-dom";
import * as React from "react";
import {
  Button,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import {GetUser} from "../../api/methods";
import Divider from "@mui/material/Divider";
import RemoveOrganizationButton from "../../admin/components/RemoveOrganizationButton";
import RenameOrganizationButton from "../../admin/components/RenameOrganizationButton";

export function Account(params) {
  const {user, organizations} = useLoaderData();
  const navigate = useNavigate();

  const organizationRoleMap = new Map();
  for (const organization of organizations) {
    for (const member of organization.members) {
      if (member.id === user.id) {
        organizationRoleMap.set(organization.id, member.role);
      }
    }
  }

  const isManager = (organization) => {
    return organizationRoleMap.get(organization.id) === 'manager';
  }
  const isOwner = (organization) => {
    return organizationRoleMap.get(organization.id) === 'owner';
  }

  return (
    <Stack spacing={2}>
      <Grid container>
        <Grid size={{xs: 12}}>
          <Typography variant="h4">
            My organizations
          </Typography>
        </Grid>
      </Grid>
      <Grid container>
        <Grid xs={12} lg={4} xl={2} sx={{my:2}}>
          <Button fullWidth variant="text" onClick={() => {
            navigate('/admin/organizations/new');
          }}>New organization</Button>
        </Grid>
      </Grid>

      <Grid container>
        <Grid size={{xs: 12}}>
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Name</TableCell>
                  <TableCell>Role</TableCell>
                  <TableCell>Members</TableCell>
                  <TableCell>Projects</TableCell>
                  <TableCell>Billing plan</TableCell>
                  <TableCell align="right">Options</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {organizations.map((organization) => (
                  <TableRow key={organization.id} sx={{'&:last-child td, &:last-child th': {border: 0}}}>
                    <TableCell component="th" scope="row">
                      {organization.name}
                    </TableCell>
                    <TableCell component="th" scope="row">
                      {organizationRoleMap.get(organization.id).charAt(0).toUpperCase() + organizationRoleMap.get(organization.id).slice(1)}
                    </TableCell>
                    <TableCell>
                      <Button variant="text" onClick={() => {
                        navigate('/admin/organizations/' + organization.id+'/members');
                      }}>View ({organization.members.length})</Button>
                    </TableCell>
                    <TableCell>
                      <Button variant="text" onClick={() => {
                        navigate('/admin/organizations/' + organization.id+'/projects');
                      }}>View ({organization.projects.length})</Button>
                    </TableCell>
                    <TableCell>
                      <Button variant="text">Free</Button>
                    </TableCell>
                    <TableCell align="right">
                      <Stack direction="row-reverse" spacing={1}>
                        {isOwner(organization) && <RemoveOrganizationButton onSuccess={() => {
                          navigate('/admin/account');
                        }} organization={organization}/>}
                        {!isOwner(organization) && <Button variant="text" color="error">Leave</Button>}
                      {(isManager(organization) || isOwner(organization)) && <RenameOrganizationButton
                        onSuccess={() => {
                          navigate('/admin/account');
                        }} organization={organization}/>}
                      </Stack>
                    </TableCell>
                  </TableRow>
                ))}
                {organizations.length === 0 && (
                  <TableRow sx={{border: 0}}>
                    <TableCell colSpan={6}>
                      No organizations
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </Grid>
      <Divider/>
      <Grid container>
        <Grid size={{xs: 12}} sx={{mt: {xs:4, sm: 8}}}>
          <Typography variant="h4">
            Danger zone
          </Typography>
        </Grid>
      </Grid>
      <Grid container>
        <Grid xs={12} lg={4} xl={2}  sx={{my:2}}>
          <Button fullWidth color="error" variant="text">Delete account</Button>
        </Grid>
      </Grid>
    </Stack>
  );
}

export async function userLoader() {
  const [code, response] = await GetUser('me');
  if (code !== 200) {
    throw new Error(`Failed to get user: ${code}`);
  }

  return {
    user: response.user,
  }
}
