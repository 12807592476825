export class MemberRoleMap {
  constructor(members) {
    this.memberRoleMap = new Map();
    for (const member of members) {
      this.memberRoleMap.set(member.id, member.role);
    }
  }

  get(memberID) {
    return this.memberRoleMap.get(memberID);
  }

  isOwner(memberID) {
    return this.get(memberID) === 'owner';
  }

  isManager(memberID) {
    return this.get(memberID) === 'manager';
  }

  isOwnerOrManager(memberID) {
    return this.isOwner(memberID) || this.isManager(memberID);
  }
}
