import {
  Button,
  Icon, Stack,
  TextField
} from "@mui/material";
import {useNavigate, useParams} from "react-router-dom";
import {CreateOrganization} from "../../api/methods";
import Grid from '@mui/material/Grid2';
import Typography from "@mui/material/Typography";
import {useState} from "react";
import {mdiSlack} from '@mdi/js';

<Icon path={mdiSlack} size={1}/>

export function OrganizationForm(params) {
  params = useParams();
  const navigate = useNavigate();

  const [organization, setOrganization] = useState({
    name: "My Organization",
    organization_id: params.organizationID,
  });

  const saveOrganization = async () => {
    const payload = {
      name: organization.name,
    };

    await CreateOrganization(payload);

    navigate('/admin/account');
  }

  return (
    <div>
      <Stack spacing={2}>
        <Grid container>
          <Grid size={{xs: 12}}>
            <Typography variant="h4">
              Create Organization
            </Typography>
          </Grid>
        </Grid>
        <Grid container>
          <Grid size={{xs: 12, md: 6, lg: 4, xl: 3}}>
            <TextField id="outlined-basic" fullWidth label="Name" variant="outlined" required value={organization.name}
                       onChange={(e) => {
                         setOrganization({...organization, name: e.target.value});
                       }}
            />
          </Grid>
        </Grid>
        <Grid container>
          <Grid size={{xs: 12, md: 6, lg: 4, xl: 3}}>
            <Button variant="text" onClick={saveOrganization}>Create</Button>
          </Grid>
        </Grid>
      </Stack>
    </div>
  );
}
