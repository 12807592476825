import { RedocStandalone } from 'redoc';

const ApiHost = process.env.REACT_APP_API_HOST;

export default function ApiDocs() {
  return (
    <RedocStandalone
      specUrl={`${ApiHost}/openapi.yml`}
      options={{
        nativeScrollbars: true,
      }}
    />
  )
}
