import {Button, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow} from "@mui/material";
import * as React from "react";
import {generatePath, useLoaderData, useNavigate} from "react-router-dom";
import Grid from "@mui/material/Grid2";
import Typography from "@mui/material/Typography";

export default function ProjectMembers(params) {
  const {user, project} = useLoaderData();
  const members = project.members;

  const memberRoleMap = new Map();
  for (const member of members) {
    memberRoleMap.set(member.id, member.role);
  }

  const isOwner = (member) => {
    return memberRoleMap.get(member.id) === 'owner';
  }

  return (
    <Stack spacing={2}>
      <Grid container>
        <Grid size={{xs: 12}}>
          <Typography variant="h4">
            {project.name} <small>members ({members.length})</small>
          </Typography>
        </Grid>
      </Grid>
      <Grid container>
        <Grid xs={12} lg={4} xl={2} sx={{my:2}}>
          <Button fullWidth variant="text">Add</Button>
        </Grid>
      </Grid>
      <Grid container>
        <Grid size={{xs: 12}}>
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Name</TableCell>
                  <TableCell>Role</TableCell>
                  <TableCell align="right">Options</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {members.map((member) => (
                  <TableRow
                    key={member.id}
                    sx={{'&:last-child td, &:last-child th': {border: 0}}}
                  >
                    <TableCell component="th" scope="row">
                      {member.name}
                    </TableCell>
                    <TableCell component="th" scope="row">
                      {member.role.charAt(0).toUpperCase() + member.role.slice(1)}
                    </TableCell>
                    <TableCell align="right">
                      <Stack direction={"row-reverse"} spacing={1}>
                      {isOwner(user) && <Button variant="text" color={"error"}>Remove</Button>}
                      {isOwner(user) && <Button variant="text">Change Role</Button>}
                      </Stack>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </Grid>
    </Stack>
  )
}
