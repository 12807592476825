export const apiErrorMapper = (code, resp) => {
  if (code < 400) {
    return null;
  }

  if (code === 400) {
    return mapBadRequest(resp);
  }
}

const mapBadRequest = (resp) => {
  const error = resp.error;
  if (!error.details) {
    return null;
  }

  let errObj = {};
  for (const detail of error.details) {
    errObj[detail.field] = detail.issue;
  }

  return errObj;
}
