const ApiHost = process.env.REACT_APP_API_HOST;

const buildUrl = (baseUrl, query) => {
  const urlObj = new URL(baseUrl);
  if (query) {
    urlObj.search = new URLSearchParams(query).toString();
  }
  return urlObj;
};

const handleResponse = async (res) => {
  if (res.status === 204) {
    return [res.status, null];
  }
  if (res.status === 401) {
    window.location.href = "/admin/sign-in?to=" + window.location.pathname;
    return [res.status, null];
  }
  return [res.status, await res.json()];
};

export const callApi = async (url, method, payload = null, query = null) => {
  const urlObj = buildUrl(`${ApiHost}${url}`, query);
  
  const options = {
    method,
    headers: {
      "Content-Type": "application/json",
    },
    credentials: "include",
    body: payload ? JSON.stringify(payload) : null,
  };

  const res = await fetch(urlObj, options);
  return handleResponse(res);
};
