import * as React from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
  Tooltip,
  Stack
} from "@mui/material";
import ContentCopyIcon from '@mui/icons-material/ContentCopy';

export default function ViewProbeURLButton(props) {
  const [open, setOpen] = React.useState(false);
  const {probe} = props;

  const handleCopy = () => {
    if (probe.url) {
      navigator.clipboard.writeText(probe.url);
    }
  };

  return (
    <React.Fragment>
      <Button variant="text" onClick={() => setOpen(true)}>View URL</Button>
      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        PaperProps={{
          component: 'form',
        }}
      >
        <DialogTitle>Probe URL</DialogTitle>
        <DialogContent>
          {probe.type === "push" ? (
            <DialogContentText>
              Send GET or POST HTTP request to this URL to trigger the probe.
            </DialogContentText>
          ) : (
            <DialogContentText>
              This URL will be polled by the probe.
            </DialogContentText>
          )}
          <Stack direction="row" alignItems="center" spacing={1} mt={2}>
            <TextField
              fullWidth
              readOnly
              variant="standard"
              value={probe.url}
            />
            <Tooltip title="Copy to clipboard">
              <Button
                variant="contained"
                color="primary"
                onClick={handleCopy}
                startIcon={<ContentCopyIcon/>}
              >
                Copy
              </Button>
            </Tooltip>
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpen(false)}>Close</Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}
