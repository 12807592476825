import {
  GetChannel,
  GetOrganization,
  ListChannels,
  ListOrganizations,
  ListProjects,
  GetProject,
  ListProbes, ListInvitations, ListIntegrations
} from "../../api/methods";

export async function withSelectedOrganizationAndProjectLoader({params}) {
  const [code, response] = await ListOrganizations();
  if (code !== 200) {
    throw new Error(`Failed to load projects: ${code}`);
  }
  const organizationID = params.organizationID;
  const projectID = params.projectID;

  const selectedOrganization = response.organizations.find((organization) => organization.id === organizationID);
  if (!selectedOrganization) {
    throw new Error(`Organization ${organizationID} not found`);
  }

  const selectedProject = selectedOrganization.projects.find((project) => project.id === projectID);
  if (!selectedProject) {
    throw new Error(`Project ${projectID} not found`);
  }

  return {
    selectedProject: selectedProject,
    selectedOrganization: selectedOrganization,
    organizations: response.organizations,
    projects: selectedOrganization.projects,
  }
}

export async function withSelectedOrganizationsLoader({params}) {
  const [code, response] = await ListOrganizations();
  if (code !== 200) {
    throw new Error(`Failed to load organizations: ${code}`);
  }
  const organizationID = params.organizationID;

  const selectedOrganization = response.organizations.find((organization) => organization.id === organizationID);
  if (!selectedOrganization) {
    throw new Error(`Organization ${organizationID} not found`);
  }

  return {
    selectedOrganization: selectedOrganization,
    organizations: response.organizations,
  }
}

export async function organizationsLoader() {
  const [code, response] = await ListOrganizations();
  if (code !== 200) {
    throw new Error(`Failed to load organizations: ${code}`);
  }

  return {
    organizations: response.organizations,
  }
}

export async function organizationLoader({params}) {
  const organizationID = params.organizationID;

  const [code, response] = await GetOrganization(organizationID);
  if (code !== 200) {
    throw new Error(`Failed to get organization: ${code}`);
  }

  return {
    organization: response.organization,
  }
}

export async function projectLoader({params}) {
  const [organizationID, projectID] = [params.organizationID, params.projectID];

  const [code, response] = await GetProject(organizationID, projectID);
  if (code !== 200) {
    throw new Error(`Failed to get project: ${code}`);
  }

  return {
    project: response.project,
  }
}

export async function projectsLoader({params}) {
  const organizationID = params.organizationID;
  const [code, response] = await ListProjects(organizationID);
  if (code !== 200) {
    throw new Error(`Failed to load projects: ${code}`);
  }

  return {
    projects: response.projects,
  };
}

export async function channelLoader({params}) {
  const [organizationID, projectID, channelID] = [params.organizationID, params.projectID, params.channelID];

  const [code, response] = await GetChannel(organizationID, projectID, channelID);
  if (code !== 200) {
    throw new Error(`Failed to load channel: ${code}`);
  }

  return {
    channel: response.channel,
  }
}

export async function channelsLoader({params}) {
  const [organizationID, projectID] = [params.organizationID, params.projectID];
  const [code, response] = await ListChannels(organizationID, projectID);
  if (code !== 200) {
    throw new Error(`Failed to load channels: ${code}`);
  }

  return {
    channels: response.channels,
  }
}

export async function listProbesLoader({params}) {
  const {organizationID, projectID} = params;
  const [code, response] = await ListProbes(organizationID, projectID);
  if (code !== 200) {
    throw new Error(`Failed to load probes: ${code}`);
  }

  return {
    probes: response.probes,
  };
}

export async function listInvitationsLoader({params}) {
  const {organizationID} = params;
  const [code, response] = await ListInvitations(organizationID);
  if (code !== 200) {
    throw new Error(`Failed to load invitations: ${code}`);
  }

  return {
    invitations: response.invitations,
  };
}


export async function listIntegrationsLoader({params}) {
  const {organizationID, projectID} = params;
  const [code, response] = await ListIntegrations(organizationID, projectID);
  if (code !== 200) {
    throw new Error(`Failed to load integrations: ${code}`);
  }

  return {
    integrations: response.integrations,
  };
}
