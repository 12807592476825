import {Button, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow} from "@mui/material";
import * as React from "react";
import {generatePath, useLoaderData, useNavigate} from "react-router-dom";
import Grid from "@mui/material/Grid2";
import Typography from "@mui/material/Typography";
import RenameProjectButton from "../../admin/components/RenameProjectButton";
import RemoveProjectButton from "../../admin/components/RemoveProjectButton";


export default function Projects(params) {
  const {user, organization, projects} = useLoaderData();
  const navigate = useNavigate();

  const projectMap = new Map();
  for (const project of projects) {
    projectMap.set(project.id, project);
  }

  const projectRoleMap = new Map();
  for (const project of projects) {
    for (const member of project.members) {
      if (member.id === user.id) {
        projectRoleMap.set(project.id, member.role);
      }
    }
  }

  const isOwner = (project) => {
    return projectRoleMap.get(project.id) === 'owner';
  }

  return (
    <Stack spacing={2}>
      <Grid container>
        <Grid size={{xs: 12}}>
          <Typography variant="h4">
            {organization.name} <small>projects ({projects.length})</small>
          </Typography>
        </Grid>
      </Grid>
      <Grid container>
        <Grid xs={12} lg={4} xl={2}>
          <Button fullWidth variant="text" onClick={() => {
            navigate(generatePath('/admin/organizations/:organizationID/projects/new', {organizationID: organization.id}));
          }}>Create</Button>
        </Grid>
      </Grid>
      <Grid container>
        <Grid size={{xs: 12}}>
          {projects.length === 0 && <Typography variant="body1">No projects</Typography>}
          {projects.length > 0 &&
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Name</TableCell>
                    <TableCell>Members</TableCell>
                    <TableCell align="right">Options</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {projects.map((project) => (
                    <TableRow
                      key={project.id}
                      sx={{'&:last-child td, &:last-child th': {border: 0}}}
                    >
                      <TableCell component="th" scope="row">
                        {project.name}
                      </TableCell>
                      <TableCell>
                        <Button variant="text" onClick={() => {
                          navigate('/admin/organizations/' + organization.id + '/projects/' + project.id + '/members');
                        }}>View ({project.members.length})</Button>
                      </TableCell>
                      <TableCell align="right">
                        <Stack direction={"row-reverse"} spacing={1}>
                          {isOwner(project) && <RemoveProjectButton onSuccess={() => {
                            navigate('/admin/organizations/' + organization.id + '/projects');
                          }} project={project}/>}
                          {(isOwner(project) && <RenameProjectButton
                              onSuccess={() => {
                                navigate('/admin/organizations/' + organization.id + '/projects');
                              }} project={projectMap.get(project.id)}/>
                          )}
                        </Stack>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          }
        </Grid>
      </Grid>
    </Stack>
  )
}
