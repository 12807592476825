import * as React from 'react';
import Container from "@mui/material/Container";
import ReactMarkdown from 'react-markdown';

const dpa = `
# Data Processing Addendum

 This Data Processing Addendum ("Addendum") forms part of the Terms of Service ("Terms") between ObsLabs.io ("Company", "we", "our") and you ("Customer", "you", "your") for the use of ObsLabs.io services ("Services").

 **1. Definitions**

 - **Personal Data**: Any information relating to an identified or identifiable natural person.
 - **Processing**: Any operation or set of operations performed on Personal Data.
 - **Data Controller**: The entity that determines the purposes and means of Processing.
 - **Data Processor**: The entity that processes Personal Data on behalf of the Data Controller.
 - **Subprocessor**: Any Data Processor engaged by the Data Processor.

 **2. Subject Matter of the Addendum**

 This Addendum applies to the Processing of Personal Data in connection with the Services as defined in the Terms.

 **3. Duration**

 This Addendum shall be effective as of the same date as the Customer's acceptance of the Terms and shall continue until the termination of the Terms.

 **4. Instructions**

 The Company shall process Personal Data only on documented instructions from the Customer unless required by law.

 **5. Confidentiality**

 The Company ensures that persons authorized to process the Personal Data have committed themselves to confidentiality or are under an appropriate statutory obligation.

 **6. Security**

 The Company shall implement appropriate technical and organizational measures to ensure a level of security appropriate to the risk, including:

 - Pseudonymization and encryption of Personal Data.
 - Ability to ensure ongoing confidentiality, integrity, availability, and resilience of Processing systems.
 - Ability to restore availability and access to Personal Data in a timely manner in the event of a physical or technical incident.
 - A process for regularly testing, assessing, and evaluating the effectiveness of technical and organizational measures.

 **7. Subprocessors**

 The Company may engage Subprocessors to assist in providing the Services. The Company shall ensure that Subprocessors are bound by obligations no less protective than those set out in this Addendum.

 **8. Data Subject Rights**

 The Company shall assist the Customer in responding to requests from data subjects exercising their rights under applicable data protection laws.

 **9. Data Breach Notification**

 The Company shall notify the Customer without undue delay after becoming aware of a Personal Data breach.

 **10. Data Transfer**

 The Company shall not transfer Personal Data outside the European Economic Area (EEA) unless it ensures adequate protection measures are in place, such as standard contractual clauses approved by the European Commission.

 **11. Deletion or Return of Personal Data**

 Upon termination of the Terms, the Company shall, at the Customer's choice, delete or return all Personal Data processed on behalf of the Customer.

 **12. Liability**

 The Company shall be liable for any damages arising from the Processing of Personal Data in violation of this Addendum or applicable data protection laws.

 **13. Governing Law**

 This Addendum shall be governed by and construed in accordance with the laws applicable to the Agreement.

 **14. Miscellaneous**

 Any amendments to this Addendum must be in writing and signed by both parties.

`;

export default function TermsOfService() {
  return (
    <Container
      id="top"
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        pt: { xs: 8, sm: 12 },
        pb: { xs: 8, sm: 12 },
      }}
    >
      <ReactMarkdown>
        {dpa}
      </ReactMarkdown>
    </Container>
  );
}
