import * as React from 'react';
import Container from '@mui/material/Container';
import {Stack, Box, useTheme} from "@mui/material";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";

export default function Features() {
  const theme = useTheme();
  const isDarkMode = theme.palette.mode === 'dark';

  return (
    <Container id="features" name="features" sx={{py: {xs: 4, sm: 12}}}>
      <Box sx={{textAlign: 'center'}}>
        <Typography variant="h2" gutterBottom>
          MONITOR WEBSITES, APIs, BACKGROUND JOBS, AND MORE
        </Typography>
        <Typography variant="body1" sx={{color: 'text.secondary'}}>
          Probes can be configured to monitor HTTP endpoints or to expose an HTTP endpoint for monitoring.
        </Typography>
      </Box>
      <Stack
        sx={{pt: {xs: 4, sm: 12}}}
        spacing={6}
        direction={{xs: 'column', sm: 'row'}}
        justifyContent="center"
        alignItems="center"
      >
        <Box sx={{width: {xs: '100%'}}}>
          <Typography variant="h4" gutterBottom>
            Pull Probes
          </Typography>
          <Typography variant="body1" sx={{color: 'text.secondary'}}>
            Pull probes are configured to monitor a service by making HTTP requests to it.
          </Typography>
          <img src={isDarkMode ? '/img/pull-dark.svg' : '/img/pull.svg'} alt="Pull Probe"
               style={{maxWidth: '100%', height: 'auto'}}/>
        </Box>
        <Box sx={{width: {xs: '100%'}}}>
          <Typography variant="h4" gutterBottom>
            Push Probes
          </Typography>
          <Typography variant="body1" sx={{color: 'text.secondary'}}>
            Push probes are configured to expose an HTTP endpoint that is used by your service.
          </Typography>
          <img src={isDarkMode ? '/img/push-dark.svg' : '/img/push.svg'} alt="Push Probe"
               style={{maxWidth: '100%', height: 'auto'}}/>
        </Box>
      </Stack>
      <Divider sx={{mt: {xs: 4, sm: 12}}}/>
      <Box sx={{textAlign: 'center', mt: {xs: 4, sm: 12}}}>
        <Typography variant="h2">
          Get notified when things go wrong
        </Typography>
        <Typography variant="body1" sx={{color: 'text.secondary'}}>
          You set Probe thresholds and ObsLabs will alert you when they are exceeded.
          Check schedules is a simple periodic interval or cron expression.
        </Typography>
      </Box>
      <Box sx={{textAlign: 'center', mt: {xs: 4, sm: 12}}}>
          <img src={isDarkMode ? '/img/channels-dark.svg' : '/img/channels.svg'} style={{maxWidth: '100%', height: 'auto'}} alt="Channels"/>
      </Box>
    </Container>
  );
}
