import * as React from 'react';
import Divider from '@mui/material/Divider';
import Pricing from './components/Pricing';
import FAQ from './components/FAQ';
import ApiAccess from './components/ApiAccess';
import Hero from "./components/Hero";
import Features from "./components/Features";

export default function Homepage() {
  return (
    <div>
      <Hero/>
      <div>
        <Features/>
        <Divider/>
        <Divider/>
        <ApiAccess/>
        <Divider/>
        <Pricing/>
        <Divider/>
        <FAQ/>
      </div>
    </div>
  );
}
